<template>
    <div>
        <div class="wrapper">
            <div
                v-if="!dataLoading"
                class="friends_list_card"
            >
                <div class="friends_list_card-left">
                    <div class="friends_list_card-left__user">
                        <span
                            v-if="friend.country"
                            class="friends_list_card-left__user__flag"
                            :class="`review__flag flag-icon flag-icon-` + friend.country.toLowerCase()"
                        />
                        <div
                            v-else
                            class="friends_list_card__avatar"
                        >
                            <avatar
                                :firstname="friend.firstname"
                                :lastname="friend.lastname"
                                :email="friend.email"
                            />
                        </div>
                        <div class="friends_list_card-left__user-info">
                            <span class="friends-card__name">
                                {{ `${friend.lastname}, ${friend.firstname} ` }}
                            </span>
                            <span class="friends-card__email">{{ friend.email }}</span>
                        </div>
                    </div>
                </div>
                <div class="friends_list_card-right friends_list_card-right-register">
                    <!-- total orders -->
                    <p class="friends-card__credits">
                        {{ friend.created_at | moment_bidding_text }}
                        <br>
                        <span>created at</span>
                    </p>
                    <!-- total orders -->
                    <div class="friends-card__orders">
                        {{ friend.orders }}
                        <br><span>orders</span>
                    </div>
                    <!-- total orders -->
                    <!-- total credits -->
                    <div class="friends-card__credits">
                        {{ friend.purchases | money }}
                        <br>
                        <span>purchases</span>
                    </div>
                    <div class="friends-card__credits">
                        {{ friend.rewards | money }}
                        <br>
                        <span>rewards</span>
                    </div>
                    <!-- total credits -->
                </div>
            </div>
        </div>

        <RadiusLoader
            v-if="dataLoading && !batches.length"
        />
        <div v-else>
            <div class="batches-table-header">
                <div>In-x</div>
                <div>Date</div>
                <div>Order</div>
                <div>Purchase</div>
                <div>Reward</div>
            </div>
            <div
                v-if="batches.length > 0 && !dataLoading"
                class="friend-detail__content"
            >
                <div class="friend-detail__list">
                    <div
                        v-for="(item, index) in batches"
                        :key="item.id"
                        class="friend-detail__list-item"
                    >
                        <div class="friend-detail__list-item__details">
                            <div class="friend-detail__list-item__details-item">
                                <div class="friend-detail__list-item__details-item__title">
                                    In-x
                                </div>
                                <div class="friend-detail__list-item__details-item__val">
                                    {{ index + 1 }}.
                                </div>
                            </div>
                            <div class="friend-detail__list-item__details-item friend-detail__list-item__details-item__start">
                                <div class="friend-detail__list-item__details-item__title">
                                    Ordered: {{ item.created_at | moment_from }} ago
                                </div>
                                <div class="friend-detail__list-item__details-item__val">
                                    <clock />
                                    {{ item.created_at | moment_short_text }}
                                </div>
                            </div>
                            <div class="friend-detail__list-item__details-item friend-detail__list-item__details-item__order">
                                <div class="friend-detail__list-item__details-item__title">
                                    Order
                                </div>
                                <div class="friend-detail__list-item__details-item__val">
                                    <Barcode />
                                    <p>
                                        {{ item.orderid }}
                                    </p>
                                </div>
                            </div>
                            <div class="friend-detail__list-item__details-item friend-detail__list-item__details-item__total">
                                <div class="friend-detail__list-item__details-item__title">
                                    Purchase
                                </div>
                                <div class="friend-detail__list-item__details-item__val">
                                    <img
                                        src="@/assets/img/svg/walet.svg"
                                        alt=""
                                    >
                                    <p style="opacity: 0.5">
                                        {{ item.total | money }}
                                    </p>
                                </div>
                            </div>
                            <div class="friend-detail__list-item__details-item friend-detail__list-item__details-item__total">
                                <div class="friend-detail__list-item__details-item__title">
                                    Reward
                                </div>
                                <div class="friend-detail__list-item__details-item__val">
                                    <BoardCheck />
                                    <p style="font-size: 18px">
                                        {{ item.reward | money }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <no-result
            v-if="!batches.length"
            :type="'friend'"
        />
    </div>
</template>

<script>
import Clock from 'mdi-vue/Clock';
import Barcode from 'mdi-vue/Barcode.vue';
import BoardCheck from 'mdi-vue/ClipboardCheckOutline'
import Api from '@/helpers/api/index.js';
import filtersMixin from '@/mixins/filters-mixin.js'
import NoResult from '@/components/common/NoResult';
import Avatar from '@/components/common/Avatar';
import { eventBus } from '@/helpers/event-bus/'
import RadiusLoader from '@/components/common/RadiusLoader.vue'

export default {
    name: 'FriendDetail',
    components: {
        Avatar,
        NoResult,
        Clock,
        BoardCheck,
        RadiusLoader,
        Barcode
    },
    metaInfo() {
        return {
            title: this.friend.firstname ? `${this.friend.firstname} ${this.friend.lastname}` : this.friend.email
        }
    },
    mixins: [
        filtersMixin
    ],
    data() {
        return {
            dataLoading: true,
            friend: {},
            batches: []
        }
    },

    watch: {
    },
    async created() {
        await this.getData();
    },
    methods: {
        async getData() {
            try {
                this.dataLoading = true
                const data = await Api.get('/api/client/friend/fetch', {
                    id: this.$route.params.id
                })
                this.friend = data.data.client;
                this.batches = data.data.purchases
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.dataLoading = false
            }
        }
    }
}
</script>

<style lang="scss">
    .bc-wrapper.bc-friend-details {
        min-height: 200px;
    }
    @media screen and (max-width:992px){
        .bc-wrapper.bc-friend-details {
            padding-bottom: 40px;
            .bc-wrapper_row {
                margin-top: 0;
            }
            h1 {
                margin-bottom: 14px;
            }
        }
    }
    @media screen and (max-width:768px){
        .bc-wrapper.bc-friend-details {
            padding-bottom: 40px;
            .bc-wrapper_row {
                margin-top: 0;
            }
        }
    }
    @media screen and (max-width:576px){
        .bc-wrapper.bc-horizontal.bc-wrapper_friends {
            padding-left: 5px !important;
            padding-right: 5px !important;
        }
        .bc-wrapper.bc-friend-details {
            padding-bottom: 40px;
            .bc-wrapper_row {
                margin-top: 0;
            }
            .report-wrapper {
                margin-bottom: 0;
            }
        }
    }
</style>

<style lang="scss" scoped>
    .bc-wrapper.bc-horizontal {
        padding-bottom: 20px;
        .bc-wrapper_row {
            justify-content: space-between;
            width: 100%;
        }
    }
    .friend-bar{
        display: flex;
        justify-content: space-between;
        &_details{
            display: flex;
            justify-content: flex-end;
            &__item{
                display: flex;
                align-items: center;
                &:first-child {
                    margin-right: 50px;
                }
                &-text{
                    color: #1f2939;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    .number{
                        font-size: 24px;
                        font-weight: 700;
                        margin-bottom: 4px;
                        &.earnings{
                            color: #77c93a;
                        }
                    }
                    .text{
                        color: #1f2939;
                        font-size: 18px;
                        font-weight: 400;
                    }
                }
            }
        }
        @media all and (max-width: 576px) {
            flex-direction: column;
            &_btns{
                width: 100%;
                .btn-base {
                    width: 100%;
                }
            }
            &_details{
                margin-top: 15px;
                justify-content: center;
            }
        }
    }
    .friend-detail {
        &__info {
            display: flex;
            &-img {
                width: 66px;
                height: 66px;
                border-radius: 50%;
                border: 2px solid $main-color;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            &-about {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 20px;
                color: $font-color-main;
                &__fullname {
                    font-weight: bold;
                    font-size: 22px;
                    line-height: 28px;
                    text-transform: capitalize;
                }
                &__email {
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: normal;
                }
            }
        }
        &__content {
            //background: #fff;
        }
        &__list {
            margin: auto;
            &-item {
                display: flex;
                border-bottom: 1px solid #f0f2f6;
                padding: 10px 0;
                //background: #fff;
                &:last-child {
                    margin-bottom: 0;
                }
                &__status {
                    max-width: 79px;
                    padding: 11px 23px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #77c93a;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;
                    background: rgba(89, 199, 77, 0.1);
                    border-radius: 4px;
                }
                &__details {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-grow: 2;
                    &-item {
                        display: flex;
                        flex-direction: column;
                        padding-left: 30px;
                        &:nth-child(2) {
                            flex-grow: 2;
                        }
                        &:last-child {
                            margin-right: 0;
                            padding-right: 30px;

                            .friend-detail__list-item__details-item__val {
                                width: 90px;
                            }
                        }
                        &__title {
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 20px;
                            color: #AFB2B9;
                            display: none;
                            @media screen and (max-width:992px){
                                display: flex;
                            }
                        }
                        &__val {
                            font-weight: normal;
                            margin-top: 6px;
                            font-size: 16px;
                            line-height: 20px;
                            display: flex;
                            align-items: center;
                            svg {
                                color: $second-color;
                                margin-right: 10px;
                            }
                            img {
                                width: 17px;
                                margin-right: 10px;
                            }

                            i {
                                color: $second-color;
                                margin-right: 10px;
                            }
                        }
                    }
                }
                &__unpaid {
                    height: 110px;
                    .friend-detail__list-item__status {
                        color: $second-color;
                    }
                    .friend-detail__list-item__details-item__val {
                        color: #afb2b9;
                        img {
                            filter: grayscale(1);
                        }

                        i {
                            filter: grayscale(1);
                        }
                    }
                }
            }
        }
    }

    @media all and (max-width: 1200px) {
        .friend-detail__list {
            max-width: 960px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    @media all and (max-width: 992px) {
        .friend-detail__list-item__details {
            flex-wrap: wrap;
            padding-top: 6px;
            padding-bottom: 6px;
            &-item {
                padding-left: 21px;
                width: 50%;
                &:nth-child(3), &:nth-child(4), &:nth-child(5){
                    margin-top: 10px;
                }
            }
        }
    }

    @media all and (max-width: 768px) {
        .friend-detail__list-item {
            height: auto;
            flex-direction: column;
            padding-top: 14px;
            padding-bottom: 14px;
            &__status {
                width: 100%;
                padding: 10px 0;
            }
            &__details {
                padding: 0;
            }
        }
    }

    @media all and (max-width: 540px) {
        .friend-detail__list-item {
            &__details {
                &-item {
                    margin-top: 15px !important;
                    width: 100%;
                    &:nth-child(1) {
                        margin-top: 0 !important;
                    }
                }
            }
        }
    }
</style>
<style lang="scss">
    .friend-detail__info-img {
        display: flex;
        align-items: center;
        justify-content: center;
        .avatar-wrapper {
            height: 64px;
            width: 64px;
            .avatar-img {
                height: 64px;
                width: 64px;
                span {
                    font-size: 24px;
                }
            }
        }
    }
    .friend-detail__list-item__details-item__total{
        width: 150px;
    }
    .friend-detail__list-item__details-item__order{
        width: 230px;
    }

    .batches-table-header{
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(#96a1b2, 0.5);
        border-top: 1px solid rgba(#96a1b2, 0.5);
        padding: 20px;
        @media screen and (max-width:992px){
            display: none;
        }
        div {
            font-size: 16px;
            font-weight: 500;
            color: rgba(#000000, 0.9);
        }
        div:nth-child(2) {
            padding-left: 30px;
            flex-grow: 2;
        }
        div:nth-child(3) {
            width: 230px;
        }
        div:nth-child(4) {
            width: 100px;
        }
        div:nth-child(5) {
            width: 100px;
            margin-left: 50px;
        }
    }
</style>
